import { Form } from 'react-final-form';
import { useMemo } from 'react';
import { DemandFormProps } from '@eogile/agrements-common/src/demand/components/demandComponentTypes';
import { DemandFormTitle } from '@eogile/agrements-common/src/demand/components/DemandFormTitle';
import { FormMode, FormModeProvider } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import {
  isLightDemandForm,
  isVisibleMinistryDataSection,
  isVisibleMinistryVerdictSection,
} from '@eogile/agrements-common/src/demand/demandFormUtils';
import arrayMutators from 'final-form-arrays';
import { DemandMinistryDataSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryDataSection';
import { DemandMinistryVerdictSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryVerdictSection';
import { DemandCommentSection } from '@eogile/agrements-common/src/demand/components/DemandCommentSection';
import { DemandAttachmentsSection } from '@eogile/agrements-common/src/demand/components/DemandAttachmentsSection';
import { FdjDemand } from '../fdjDemandTypes';
import { buildFdjDemandFormCommand, buildInitialFdjDemandFormValues } from '../fdjDemandFormUtils';
import { FdjDemandFormValues } from '../fdjDemandFormTypes';
import { FdjDemandGeneralInformationSection } from './FdjDemandGeneralInformationSection';
import { FdjDemandOutletSection } from './FdjDemandOutletSection';
import { DemandAcquirerSection } from '@eogile/agrements-common/src/demand/components/DemandAcquirerSection';
import { FdjDemandCandidatesArray } from './FdjDemandCandidatesArray';
import { useFdjAuthenticatedCurrentUser } from '../../security/hooks';
import { FdjDemandFormOnChanges } from './FdjDemandFormOnChanges';
import { FdjDemandFormTooltipContents } from './FdjDemandFormTooltipContents';
import { useDemandFormSubmitHelper } from '@eogile/agrements-common/src/demand/hooks/useDemandFormSubmitHelper';
import { DemandActions } from '@eogile/agrements-common/src/demand/components/actions/DemandActions';
import { ExistingAttachmentsRemovalWarningFieldsProvider } from '@eogile/agrements-common/src/demand/components/ExistingAttachmentsRemovalWarningFieldsProvider';
import { FdjDemandGedStatusItem } from './FdjDemandGedStatusItem';

export const FdjDemandForm = (props: DemandFormProps<FdjDemand>) => {
  const { demand, mode, onSubmitSuccess, onDemandRefresh } = props;
  const submitForm = useDemandFormSubmitHelper<FdjDemand>(mode, demand?.id);
  const user = useFdjAuthenticatedCurrentUser();
  const initialValues = useMemo<FdjDemandFormValues>(
    () => buildInitialFdjDemandFormValues(demand, user.userDetails),
    [demand, user],
  );

  const onSubmit = async (values: FdjDemandFormValues) => {
    return await submitForm(buildFdjDemandFormCommand(values), onSubmitSuccess!);
  };

  return (
    <FormModeProvider mode={mode}>
      <Form<FdjDemandFormValues> initialValues={initialValues} onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ExistingAttachmentsRemovalWarningFieldsProvider demand={demand}>
              <DemandFormTitle demand={demand} />
              <FdjDemandFormOnChanges />
              <FdjDemandGeneralInformationSection demand={demand} />
              <FdjDemandOutletSection />
              {!isLightDemandForm(values) && (
                <DemandAcquirerSection
                  supportsEirlFieldForNewDemands={false}
                  infoTooltips={{
                    acquirerType: <FdjDemandFormTooltipContents.AcquirerType />,
                    companyType: <FdjDemandFormTooltipContents.AcquirerCompanyType />,
                  }}
                />
              )}
              <FdjDemandCandidatesArray demand={demand} />
              {isVisibleMinistryDataSection(demand) && <DemandMinistryDataSection demand={demand} />}
              {isVisibleMinistryVerdictSection(demand, user) && (
                <DemandMinistryVerdictSection
                  verdict={demand.verdict}
                  variant="SUPPORTS_CANCELLATION_REASON"
                  additionalContent={<FdjDemandGedStatusItem demand={demand} />}
                />
              )}
              {mode === FormMode.READ && <DemandAttachmentsSection demand={demand} />}
              <DemandCommentSection />
              <DemandActions demand={demand} onDemandRefresh={onDemandRefresh} />
            </ExistingAttachmentsRemovalWarningFieldsProvider>
          </form>
        )}
      </Form>
    </FormModeProvider>
  );
};
