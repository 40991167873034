import { parseQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import {
  FdjDemandSearchCommand,
  FdjDemandsFiltersType,
  FdjDemandsLoadRequestContext,
  FdjDemandsLoadRequestParams,
  FdjDemandsSortableFields,
} from '../fdjDemandListTypes';
import { validateDateRangeFilter, validateDepartmentNumber } from '@eogile/agrements-common/src/demand/demandListUtils';
import {
  parseCommonDemandsListQueryString,
  toCommonDemandsListApiParams,
} from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { CurrentFdjApplicationUserDetails } from '../../security/types';
import { currentUserAgency, isFdjUser } from '../../user/fdjUserUtils';
import { allFdjDemandStatuses } from '../fdjDemandUtils';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { booleanSelectChoiceToBooleanValue } from '@eogile/agrements-common/src/form/helper/formUtils';
import {
  DEFAULT_DEMANDS_SORT_FIELD,
  DEFAULT_DEMANDS_SORT_ORDER,
} from '@eogile/agrements-common/src/demand/demandListTypes';
import { SortOrder } from '@eogile/agrements-common/src/components/table/tableTypes';
import { allDemandClosedStatuses } from '@eogile/agrements-common/src/demand/demandUtils';

export function parseFdjDemandsListQueryString(
  context: FdjDemandsLoadRequestContext,
  search: string,
  userDetails: CurrentFdjApplicationUserDetails,
): FdjDemandsLoadRequestParams {
  const params = parseQueryString(search);
  const defaultSortField = context === 'DEMANDS_LIST' ? DEFAULT_DEMANDS_SORT_FIELD : 'GED_SENT_DATE_TIME';
  const defaultSortOrder = context === 'DEMANDS_LIST' ? DEFAULT_DEMANDS_SORT_ORDER : 'DESC';
  return {
    ...parseCommonDemandsListQueryString(search),
    outletCode: (params.outletCode as string) ?? null,
    outletName: (params.outletName as string) ?? null,
    demandYear: (params.demandYear as string) ?? null,
    regionalDirectionId: (params.regionalDirectionId as string) ?? null,
    agencyCode: (params.agencyCode as string) ?? currentUserAgency(userDetails)?.code ?? null,
    candidateName: (params.candidateName as string) ?? null,
    sortField: (params.sortField as FdjDemandsSortableFields) ?? defaultSortField,
    sortOrder: (params.sortOrder as SortOrder) ?? defaultSortOrder,
    hasSignedVerdictFile: params.hasSignedVerdictFile ?? null,
    gedRequestStatus: params.gedRequestStatus ?? null,
  };
}

export function toFdjDemandsListApiParams(
  context: FdjDemandsLoadRequestContext,
  params: FdjDemandsLoadRequestParams,
  userDetails: CurrentFdjApplicationUserDetails,
): FdjDemandSearchCommand {
  const buildStatuses = () => {
    if (context === 'DEMANDS_LIST') {
      if (isFdjUser(userDetails)) {
        return params.status ? [params.status] : [];
      } else {
        // ministry users cannot see CREATED demands
        return params.status ? [params.status] : allFdjDemandStatuses.filter((s) => s !== DemandStatus.CREATED);
      }
    } else {
      return allDemandClosedStatuses;
    }
  };
  return {
    statuses: buildStatuses(),
    outletCode: params.outletCode,
    outletName: params.outletName,
    demandYear: params.demandYear ?? null,
    regionalDirectionId: params.regionalDirectionId,
    agencyCode: params.agencyCode ?? currentUserAgency(userDetails)?.code,
    candidateName: params.candidateName,
    hasSignedVerdictFile: booleanSelectChoiceToBooleanValue(params.hasSignedVerdictFile),
    gedRequestStatus: params.gedRequestStatus,
    ...toCommonDemandsListApiParams(params),
  };
}

export const fdjDemandsFiltersValidator = (formValues: FdjDemandsFiltersType) => {
  return {
    departmentNumber: validateDepartmentNumber(formValues.departmentNumber),
    receptionDateUpperBound: validateDateRangeFilter(
      formValues.receptionDateLowerBound,
      formValues.receptionDateUpperBound,
    ),
    validationDateUpperBound: validateDateRangeFilter(
      formValues.validationDateLowerBound,
      formValues.validationDateUpperBound,
    ),
    verdictValidationDateUpperBound: validateDateRangeFilter(
      formValues.verdictValidationDateLowerBound,
      formValues.verdictValidationDateUpperBound,
    ),
  };
};
