import { AppContextType } from '@eogile/agrements-common/src/AppContext';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import {
  currentUserMinistryTerritorialArea,
  ministryProfileDisplayLabels,
} from '@eogile/agrements-common/src/user/userUtils';
import { currentUserAgency, fdjProfileDisplayLabels } from './user/fdjUserUtils';
import { MinistryUserProfile } from '@eogile/agrements-common/src/user/ministryUserTypes';
import { FdjUserProfile } from './user/fdjUserTypes';
import { FdjDemand } from './demand/fdjDemandTypes';
import { FdjDemandForm } from './demand/components/FdjDemandForm';
import { FdjDemandsList } from './demand/FdjDemandsList';
import { CurrentFdjApplicationUserDetails } from './security/types';
import { CurrentUserDetails } from '@eogile/agrements-common/src/security/types';
import { FdjDemandsLoadRequestParams } from './demand/fdjDemandListTypes';
import { initialStatusFilterValueIfLocalMinistryUser } from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { fdjCanDoDemandActionPostFilter } from './demand/fdjDemandActionRightUtils';
import { CancellationReason } from '@eogile/agrements-common/src/demand/demandTypes';
import { fdjAttachmentHelper } from './demand/fdjDemandAttachmentUtils';
import { FdjVerdictInputDialog } from './demand/components/actions/verdictinput/FdjVerdictInputDialog';
import FdjUsersList from './user/list/components/FdjUsersList';
import FdjUserForm from './user/details/components/FdjUserForm';
import { FdjDirectionAdminPage } from './direction/FdjDirectionAdminPage';
import { DirectionLabelUsage } from '@eogile/agrements-common/src/components/direction/ImportAgencyTypes';
import { Route } from 'react-router-dom';
import { getGedSupervision } from '@eogile/agrements-common/src/navigation/navigationUrlService';
import { GedSupervisionPage } from './ged/GedSupervisionPage';

const allProfileLabels = { ...ministryProfileDisplayLabels, ...fdjProfileDisplayLabels };
export const FdjContext: AppContextType = {
  appName: 'Outil Agréments SCCJ',
  login: {
    saml: { samlUserType: UserCompany.FDJ },
  },
  profileDisplayLabel: (profile) => allProfileLabels[profile as MinistryUserProfile | FdjUserProfile],
  demand: {
    title: (demand) => {
      const fdjDemand = demand as FdjDemand;
      return `Demande: ${fdjDemand.outlet.name} - ${fdjDemand.outlet.address.zipCode} ${fdjDemand.outlet.address.city}`;
    },
    DemandForm: FdjDemandForm,
    DemandsList: FdjDemandsList,
    canDoActionPostFilter: fdjCanDoDemandActionPostFilter,
    availableCancellationReasons: [
      CancellationReason.NO_PRESENTATION,
      CancellationReason.FILE_ERROR,
      CancellationReason.FDJ_OTHER,
    ],
    attachmentHelper: fdjAttachmentHelper,
    attachmentCanBeAddedAfterValidation: false,
    VerdictInputDialog: FdjVerdictInputDialog,
  },
  menu: {
    demandListQueryParameters: (userDetails: CurrentUserDetails) => {
      const appUserDetails = userDetails as CurrentFdjApplicationUserDetails;
      const params: Partial<Record<keyof FdjDemandsLoadRequestParams, string>> = {
        agencyCode: currentUserAgency(appUserDetails)?.code,
        status: initialStatusFilterValueIfLocalMinistryUser(appUserDetails),
        territorialArea: currentUserMinistryTerritorialArea(appUserDetails),
      };
      return params;
    },
  },
  directionAdmin: {
    AppDirectionAdminPage: FdjDirectionAdminPage,
    buildDirectionLabel: (usage) => {
      switch (usage) {
        case DirectionLabelUsage.MENU_TITLE:
          return 'Gestion des secteurs';
        case DirectionLabelUsage.NOMBER_CREATED:
          return 'Nombre de secteurs créés';
        case DirectionLabelUsage.NOMBER_UPDATED:
          return 'Nombre de secteurs mis à jour';
        case DirectionLabelUsage.NOMBER_DELETED:
          return 'Nombre de secteurs supprimés';
        case DirectionLabelUsage.ERROR_TITLE:
          return 'le secteur';
        case DirectionLabelUsage.ERROR_MESSAGE:
          return 'ce secteur';
      }
    },
  },
  reassignDemands: {
    cardTitle: 'Ré-affecter les secteurs',
  },
  users: {
    UsersList: FdjUsersList,
    UserForm: FdjUserForm,
  },
  sessionWatcher: {
    warningLimitInSecond: 1500,
    endOfSessionLimitInSecond: 1800,
  },
  appSpecificRoutes: <Route path={getGedSupervision().getUrl()} element={<GedSupervisionPage />} />,
};
