import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Card, CardContent, CardHeader, Collapse, Grid } from '@mui/material';
import FormFiltersListener from '@eogile/agrements-common/src/form/components/FormFiltersListener';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import CollapsableCardHeaderAction from '@eogile/agrements-common/src/components/card/CollapsableCardHeaderAction';
import DateRangePickerAdapter from '@eogile/agrements-common/src/form/components/DateRangePickerAdapter';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { FdjDemandsFiltersType, FdjDemandsLoadRequestParams } from '../fdjDemandListTypes';
import { useFdjAuthenticatedCurrentUser } from '../../security/hooks';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import dayjs from 'dayjs';
import { fdjDemandsFiltersValidator } from './fdjDemandsFiltersUtils';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import { fixedLength, onlyDigits } from '@eogile/agrements-common/src/form/helper/validators';
import { RegionalDirection } from '@eogile/agrements-common/src/common-types/directionTypes';
import { currentUserAgency } from '../../user/fdjUserUtils';
import { allFdjDemandStatuses, computeAgencyName } from '../fdjDemandUtils';
import AgencySelect from '@eogile/agrements-common/src/components/agencies/AgencySelect';
import MinistryTerritorialAreaSelect from '@eogile/agrements-common/src/components/ministry-territorial-area/ministryTerritorialAreaSelect';

type FdjDemandsFiltersProps = {
  initialValues: FdjDemandsFiltersType;
  formValuesToQueryString: (values: FdjDemandsLoadRequestParams) => Record<string, string>;
};

const FdjDemandsFiltersFields = ({ initialValues, formValuesToQueryString }: FdjDemandsFiltersProps) => {
  const { userDetails } = useFdjAuthenticatedCurrentUser();
  const defaultOpened = Object.values(initialValues).some(
    (value) => Boolean(value) && value !== dayjs().year().toString(),
  );
  const [opened, setOpened] = useState(defaultOpened);

  const { data: regionalDirectionList } = useDataLoader<RegionalDirection[]>('api/regional-directions');
  const toggleOpened = () => {
    setOpened(!opened);
  };

  if (regionalDirectionList === null) {
    return null;
  }

  const regionalDirectionOptions = regionalDirectionList.map((rd) => ({ id: rd.id, name: rd.name }));
  const statusOptions = allFdjDemandStatuses
    // If the user belongs to the ministry, requests with the "CREATED" status cannot be displayed
    .filter((p) => (userDetails.company === UserCompany.MINISTRY ? p !== DemandStatus.CREATED : true))
    .map((s) => ({ id: s, name: demandStatusLabels[s] }));
  const currentUserIsRestrictedToAgency = Boolean(currentUserAgency(userDetails));

  return (
    <Card>
      <CardHeader
        sx={{ cursor: 'pointer', padding: '5px 10px' }}
        onClick={toggleOpened}
        title="Critères"
        action={<CollapsableCardHeaderAction opened={opened} toggleOpened={toggleOpened} />}
      />
      <CardContent>
        <TextFieldAdapter
          placeholder="Rechercher un nom, une enseigne, un code pdv, une ville ou un numéro chrono..."
          name="fullText"
          label="Recherche libre"
        />
      </CardContent>
      <Collapse in={opened} data-testid="collapsable-card-content">
        <CardContent>
          <FormFiltersListener transformValues={formValuesToQueryString} />
          <Grid container rowSpacing={2}>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="outletName" label="Nom du point de vente" />
              </Grid>
              <Grid item xs={6}>
                <SelectAdapter
                  disabled={currentUserIsRestrictedToAgency}
                  name="regionalDirectionId"
                  label="Direction régionale"
                  options={regionalDirectionOptions}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="outletCode" label="Code détaillant" />
              </Grid>
              <Grid item xs={6}>
                <AgencySelect
                  agencyName={computeAgencyName}
                  disabled={currentUserIsRestrictedToAgency}
                  fieldLabel="Secteur"
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="validationDateLowerBound"
                  upperBoundFieldName="validationDateUpperBound"
                  label="Date d'envoi du dossier"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="departmentNumber" label="Numéro du département" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="receptionDateLowerBound"
                  upperBoundFieldName="receptionDateUpperBound"
                  label="Date de réception ministère"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="chronoNumber" label="Numéro de dossier" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter
                  name="demandYear"
                  label="Année de la demande"
                  validators={[onlyDigits, fixedLength(4)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="candidateName" label="Nom candidat" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <SelectAdapter name="status" label="Statut" options={statusOptions} />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="ministryReference" label="Référence du ministère" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <MinistryTerritorialAreaSelect fieldName="territorialArea" />
              </Grid>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="verdictValidationDateLowerBound"
                  upperBoundFieldName="verdictValidationDateUpperBound"
                  label="Date de validation de l'avis"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const FdjDemandsFilters = ({ initialValues, formValuesToQueryString }: FdjDemandsFiltersProps) => {
  return (
    <Form initialValues={initialValues} validate={fdjDemandsFiltersValidator} subscription={{}} onSubmit={() => {}}>
      {() => (
        <FdjDemandsFiltersFields initialValues={initialValues} formValuesToQueryString={formValuesToQueryString} />
      )}
    </Form>
  );
};

export default FdjDemandsFilters;
