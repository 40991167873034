import { toNullSafeDayJsFormValue } from '@eogile/agrements-common/src/form/helper/formUtils';
import { FdjCandidateType, FdjDemand, FdjFullDemand, FdjLightDemand } from './fdjDemandTypes';
import {
  FdjDemandFormCommand,
  FdjDemandFormValues,
  FdjFullDemandFormCommandSpecificValues,
  FdjFullDemandFormSpecificValues,
  FdjLightDemandFormCommandSpecificValues,
  FdjLightDemandFormSpecificValues,
} from './fdjDemandFormTypes';
import {
  toAcquirerCommand,
  toAcquirerFormValues,
  toCandidateCommand,
  toCandidateFormValues,
  toOutletAddressCommand,
  toOutletAddressFormValues,
  toPhoneNumbersCommand,
  toPhoneNumbersFormValues,
} from '@eogile/agrements-common/src/demand/demandFormUtils';
import { isFdjLightDemand } from './fdjDemandUtils';
import { currentUserAgency } from '../user/fdjUserUtils';
import { CurrentFdjApplicationUserDetails } from '../security/types';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';
import { capitalize, trim, uppercase } from '@eogile/agrements-common/src/utils/stringUtils';
import { formatLocalDate } from '@eogile/agrements-common/src/utils/dateUtils';

export const buildInitialFdjDemandFormValues = (
  demand: FdjDemand | undefined,
  user: CurrentFdjApplicationUserDetails,
): FdjDemandFormValues => {
  return {
    agencyCode: demand?.agency.code ?? currentUserAgency(user)?.code,
    caseType: demand?.caseType,
    agreementType: demand?.agreementType,
    ministryReference: demand?.ministryReference,
    comment: demand?.comment,
    ongoingPmuDemand: demand?.ongoingPmuDemand ?? false,
    previousChronoNumber: demand?.previousChronoNumber,
    integratedDemand: {
      integratedDemand: demand?.integratedDemand.integratedDemand ?? false,
      rootDemandChronoNumber: demand?.integratedDemand.integratedDemand
        ? demand.integratedDemand.rootDemandChronoNumber
        : undefined,
    },
    ...(demand && isFdjLightDemand(demand)
      ? appendInitialLightDemandFormValues(demand)
      : appendInitialFullDemandFormValues(demand)),
  };
};

const appendInitialLightDemandFormValues = (demand: FdjLightDemand): FdjLightDemandFormSpecificValues => {
  const { outlet } = demand;
  return {
    receivedGrantedVerdictInPast12Months: true,
    outlet: {
      name: outlet.name,
      code: outlet.code,
      address: toOutletAddressFormValues(outlet.address),
    },
    candidates: demand.candidates.map((candidate) => ({
      civility: candidate.civility,
      lastName: candidate.lastName,
      firstNames: candidate.firstNames,
      birthCity: candidate.birthCity,
      birthDate: toNullSafeDayJsFormValue(candidate.birthDate),
    })),
  };
};

const appendInitialFullDemandFormValues = (demand: FdjFullDemand | undefined): FdjFullDemandFormSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: false,
    outlet: {
      name: demand?.outlet.name,
      code: demand?.outlet.code,
      operatingMode: demand?.outlet.operatingMode,
      acquisitionDate: toNullSafeDayJsFormValue(demand?.outlet.acquisitionDate),
      address: toOutletAddressFormValues(demand?.outlet.address),
      phoneNumbers: toPhoneNumbersFormValues(demand?.outlet.phoneNumbers),
    },
    acquirer: toAcquirerFormValues(demand),
    // force Final-Form to initialize with an empty candidate
    candidates: demand?.candidates ? demand.candidates.map(toCandidateFormValues) : [undefined as any],
  };
};

export const prefillRequestHiddenFieldName = '__ROOT_DEMAND_PREFILL_REQUEST__';

export const fdjCandidateTypesCompatibleWith = (acquirerType?: AcquirerType): FdjCandidateType[] => {
  if (!acquirerType) {
    return [];
  } else if (acquirerType === AcquirerType.IN_OWN_NAME) {
    return [FdjCandidateType.LEGAL_REPRESENTATIVE, FdjCandidateType.ASSOCIATE];
  } else {
    return [
      FdjCandidateType.PRESIDENT,
      FdjCandidateType.MANAGER,
      FdjCandidateType.CO_MANAGER,
      FdjCandidateType.ASSOCIATE,
    ];
  }
};

export const buildFdjDemandFormCommand = (formValues: FdjDemandFormValues): FdjDemandFormCommand => {
  return {
    ministryReference: trim(formValues.ministryReference),
    comment: trim(formValues.comment),
    agencyCode: formValues.agencyCode!,
    caseType: formValues.caseType!,
    agreementType: formValues.agreementType,
    integratedDemand: {
      integratedDemand: formValues.integratedDemand.integratedDemand,
      rootDemandChronoNumber: formValues.integratedDemand.rootDemandChronoNumber,
    },
    ongoingPmuDemand: formValues.ongoingPmuDemand,
    previousChronoNumber: trim(formValues.previousChronoNumber),
    ...(formValues.receivedGrantedVerdictInPast12Months
      ? appendLightDemandCommandValues(formValues)
      : appendFullDemandCommandValues(formValues)),
  };
};

const appendLightDemandCommandValues = (
  formValues: FdjLightDemandFormSpecificValues,
): FdjLightDemandFormCommandSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: true,
    outlet: {
      code: trim(formValues.outlet.code)!,
      name: trim(uppercase(formValues.outlet.name))!,
      address: toOutletAddressCommand(formValues.outlet.address),
    },
    candidates: formValues.candidates.map((candidate) => ({
      lastName: trim(uppercase(candidate.lastName))!,
      firstNames: trim(capitalize(candidate.firstNames))!,
      birthCity: trim(uppercase(candidate.birthCity))!,
      birthDate: formatLocalDate(candidate.birthDate!),
      civility: candidate.civility!,
    })),
  };
};

const appendFullDemandCommandValues = (
  formValues: FdjFullDemandFormSpecificValues,
): FdjFullDemandFormCommandSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: false,
    acquirer: toAcquirerCommand(formValues.acquirer),
    outlet: {
      name: trim(uppercase(formValues.outlet.name))!,
      code: trim(formValues.outlet.code)!,
      operatingMode: formValues.outlet.operatingMode!,
      acquisitionDate: formatLocalDate(formValues.outlet.acquisitionDate!),
      phoneNumbers: toPhoneNumbersCommand(formValues.outlet.phoneNumbers),
      address: toOutletAddressCommand(formValues.outlet.address),
    },
    candidates: formValues.candidates.map(toCandidateCommand),
  };
};
