import { FdjDemand } from './fdjDemandTypes';
import ApiHelper from '@eogile/agrements-common/src/api/apiHelper';
import { APPLICATION_JSON_DETAILED } from '@eogile/agrements-common/src/utils/mediaTypeUtils';
import { CurrentFdjApplicationUserDetails } from '../security/types';
import { currentUserAgency } from '../user/fdjUserUtils';
import { allNonClosedStatuses } from '@eogile/agrements-common/src/demand/demandUtils';
import { FdjDemandSearchCommand } from './fdjDemandListTypes';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';

const findDemandWithGrantedVerdictByChronoNumber = async (chronoNumber: string, userDetails: CurrentFdjApplicationUserDetails): Promise<FdjDemand> => {
  const command: FdjDemandSearchCommand = {
    page: 0,
    pageSize: 1,
    exactChronoNumber: chronoNumber,
    statuses: [DemandStatus.GRANTED_VERDICT, DemandStatus.GRANTED_VERDICT_OUTSIDE_TIME_LIMIT],
    agencyCode: currentUserAgency(userDetails)?.code ?? null,
  };
  const matchingDemands: FdjDemand[] = await ApiHelper.get('/api/demands', command, {
    'Content-Type': APPLICATION_JSON_DETAILED,
  });
  if (matchingDemands.length > 0) {
    return matchingDemands[0];
  }
  throw new Error('Could not find existing demand for this chrono number');
};

const findOngoingDemandByOutletCode = async (
  outletCode: string,
  userDetails: CurrentFdjApplicationUserDetails): Promise<FdjDemand | null> => {
  const command: FdjDemandSearchCommand = {
    page: 0,
    pageSize: 1,
    exactOutletCode: outletCode,
    agencyCode: currentUserAgency(userDetails)?.code ?? null,
    statuses: allNonClosedStatuses,
  };
  const ongoingDemands: FdjDemand[] = await ApiHelper.get('/api/demands', command, {
    'Content-Type': APPLICATION_JSON_DETAILED,
  });
  return ongoingDemands.length > 0 ? ongoingDemands[0] : null;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findDemandWithGrantedVerdictByChronoNumber,
  findOngoingDemandByOutletCode,
};
