import { useForm } from 'react-final-form';
import { useEffect } from 'react';
import { useFdjCaseTypeFormValue } from './useFdjCaseTypeFormValue';
import { FdjCandidateType, FdjCaseType } from '../fdjDemandTypes';
import { usePrevious } from '@eogile/agrements-common/src/hooks/usePrevious';
import { useIsIntegratedDemandFormValue } from './useIsIntegratedDemandFormValue';
import { fdjCandidateTypesCompatibleWith, prefillRequestHiddenFieldName } from '../fdjDemandFormUtils';
import {
  lightDemandFormField,
  useIsLightDemandFormHelper,
} from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { useFieldArray } from 'react-final-form-arrays';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { ongoingPmuDemandFormField, useIsOngoingPmuDemandFormValue } from './useIsOngoingPmuDemandFormValue';
import { useAcquirerTypeFormValue } from '@eogile/agrements-common/src/demand/hooks/useAcquirerTypeFormValue';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';

export const useFdjDemandFormChangesHelper = () => {
  useCaseTypeChangeFormHelper();
  useIntegratedDemandChangeFormHelper();
  useLightDemandChangeFormHelper();
  useOngoingPmuDemandChangeFormHelper();
  useAcquirerTypeChangeFormHelper();
};

const useCaseTypeChangeFormHelper = () => {
  const { change } = useForm();
  const { value: caseTypeValue } = useFdjCaseTypeFormValue();
  const previousCaseTypeValue = usePrevious(caseTypeValue);

  useEffect(() => {
    if (previousCaseTypeValue !== caseTypeValue) {
      change('agreementType', undefined);
      if (caseTypeValue !== FdjCaseType.CREATION) {
        change('integratedDemand.integratedDemand', false);
        change('integratedDemand.rootDemandChronoNumber', undefined);
      }
    }
  }, [previousCaseTypeValue, caseTypeValue, change]);
};

const useIntegratedDemandChangeFormHelper = () => {
  const { change } = useForm();
  const { value: isIntegratedDemand } = useIsIntegratedDemandFormValue();

  useEffect(() => {
    if (!isIntegratedDemand) {
      change('integratedDemand.rootDemandChronoNumber', undefined);
      change(prefillRequestHiddenFieldName, undefined);
    }
  }, [isIntegratedDemand, change]);
};

const useLightDemandChangeFormHelper = () => {
  const { change, getFieldState } = useForm();
  const isLightDemand = useIsLightDemandFormHelper();
  const fieldArray = useFieldArray('candidates', { subscription: { length: true } });

  useEffect(() => {
    if (isLightDemand) {
      const clearOutlet = () => {
        const fieldName = withFieldNamePrefix('outlet');
        change(fieldName('operatingMode'), undefined);
        change(fieldName('acquisitionDate'), undefined);
        change(fieldName('phoneNumbers'), undefined);
      };
      const clearCandidate = (name: string) => {
        const fieldName = withFieldNamePrefix(name);
        change(fieldName('type'), undefined);
        change(fieldName('birthName'), undefined);
        change(fieldName('birthDepartmentOrCountry'), undefined);
        change(fieldName('email'), undefined);
        change(fieldName('address'), undefined);
        change(fieldName('phoneNumbers'), undefined);
      };
      change('acquirer', undefined);
      clearOutlet();
      fieldArray.fields.forEach(clearCandidate);
    } else {
      const isOngoingPmuDemand = Boolean(getFieldState(ongoingPmuDemandFormField)?.value);
      if (!isOngoingPmuDemand) {
        change('previousChronoNumber', undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLightDemand, change, getFieldState]);
};

const useOngoingPmuDemandChangeFormHelper = () => {
  const { change, getFieldState } = useForm();
  const { value: isOngoingPmuDemand } = useIsOngoingPmuDemandFormValue();

  useEffect(() => {
    const isLightDemand = Boolean(getFieldState(lightDemandFormField)?.value);
    if (!isOngoingPmuDemand && !isLightDemand) {
      change('previousChronoNumber', undefined);
    }
  }, [isOngoingPmuDemand, change, getFieldState]);
};

const useAcquirerTypeChangeFormHelper = () => {
  const { change, getFieldState } = useForm();
  const acquirerType = useAcquirerTypeFormValue();
  const previousAcquirerType = usePrevious(acquirerType);
  const { fields } = useFieldArray('candidates', { subscription: { length: true } });

  useEffect(() => {
    const clearEirl = () => {
      change('acquirer.eirl', undefined);
    };
    const clearCompanyType = () => {
      change('acquirer.companyType', undefined);
    };
    if (!acquirerType) {
      clearEirl();
      clearCompanyType();
    } else if (acquirerType === AcquirerType.COMPANY) {
      clearEirl();
    } else {
      clearCompanyType();
    }

    fields.forEach((name, index) => {
      const candidateTypeFieldName = `${name}.type`;
      if (acquirerType === AcquirerType.IN_OWN_NAME) {
        change(
          candidateTypeFieldName,
          index === 0 ? FdjCandidateType.LEGAL_REPRESENTATIVE : FdjCandidateType.ASSOCIATE,
        );
      } else {
        const currentCandidateType = getFieldState(candidateTypeFieldName)?.value as FdjCandidateType | undefined;
        // we take "previousAcquirerType" into account to forcefully reset the form value in case a candidate has the
        // "ASSOCIATE" type that is compatible with both acquirer types.
        if (
          previousAcquirerType !== acquirerType ||
          (currentCandidateType && !fdjCandidateTypesCompatibleWith(acquirerType).includes(currentCandidateType))
        ) {
          change(candidateTypeFieldName, undefined);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAcquirerType, acquirerType, change]);
};
